import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () =>
            import ('@/views/home'),
        meta: {
            keepAlive: true // 该路由会被缓存
        }
    }, {
        // 详情页
        path: '/detailPage',
        name: 'detailPage',
        component: () =>
            import ('@/views/detailPage'),
        meta: {
            keepAlive: false
        }
    },
    {
        // 活动详情
        path: '/activityDetail',
        name: 'activityDetail',
        component: () =>
            import ('@/views/activityDetail'),
        meta: {
            keepAlive: false
        }
    },
    {
        // 往期活动详情
        path: '/pastDetail',
        name: 'pastDetail',
        component: () =>
            import ('@/views/pastDetail'),
        meta: {
            keepAlive: false
        }
    },
    // 搜索页
    {
        path: '/search',
        name: 'search',
        component: () =>
            import ('@/views/search'),
        meta: {
            keepAlive: false
        }
    },
    // 我的
    {
        path: '/my',
        name: 'my',
        component: () =>
            import ('@/views/my'),
        meta: {
            keepAlive: false
        }
    },
    // 我的积分
    {
        path: '/myIntegral',
        name: 'myIntegral',
        component: () =>
            import ('@/views/myIntegral'),
        meta: {
            keepAlive: false
        }
    },
    // 积分商城
    {
        path: '/pointReward',
        name: 'pointReward',
        component: () =>
            import ('@/views/pointReward'),
        meta: {
            keepAlive: false
        }
    },
    // 确定订单
    {
        path: '/order',
        name: 'order',
        component: () =>
            import ('@/views/order'),
        meta: {
            keepAlive: false
        }
    },
    // 信息填写
    {
        path: '/information',
        name: 'information',
        component: () =>
            import ('@/views/information'),
        meta: {
            keepAlive: false
        }
    },
    // 我的奖品
    {
        path: '/myprize',
        name: 'myprize',
        component: () =>
            import ('@/views/myprize'),
        meta: {
            keepAlive: false
        }
    },
    // 地方文化
    {
        path: '/localCultrue',
        name: 'localCultrue',
        component: () =>
            import ('@/views/localCultrue'),
        meta: {
            keepAlive: false
        }
    },
    // 详情
    {
        path: '/localDetail',
        name: 'localDetail',
        component: () =>
            import ('@/views/localDetail'),
        meta: {
            keepAlive: false
        }
    },
    // 我的活动
    {
        path: '/myActivity',
        name: 'myActivity',
        component: () =>
            import ('@/views/myActivity'),
        meta: {
            keepAlive: false
        }
    },
    // 活动列表
    {
        path: '/activityList',
        name: 'activityList',
        component: () =>
            import ('@/views/activityList'),
        meta: {
            keepAlive: false
        }
    },
]


const router = new VueRouter({
        mode: 'history',
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { x: 0, y: 0 }
            }
        },

    })
    // 使用路由守卫，在页面/路由跳转后，将body 的overflow设置为auto
router.afterEach((to, from, next) => {
    document.querySelector("body").setAttribute("style", "overflow: auto !important;")
})

export default router