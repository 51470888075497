
<template>
  <div id="app">
    <div class="wrap">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"> </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"> </router-view>
    </div>
  </div>
</template>

<script>
// this.reload()页面刷新调用

export default {
  name: "App",
  components: {},
  props: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, //刷新
    };
  },
  computed: {},
  watch: {},
  created() {
    // 关闭浏览器窗口事件
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("activeIdx");
    });
  },
  mounted() {},
  destroyed() {
    localStorage.removeItem("activeIdx");
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>


<style scoped lang="less">
#app {
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .wrap {
    width: 65%;
    margin: 0 auto;
  }
}
</style>

